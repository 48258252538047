import React, { useEffect }  from 'react';
import './App.css';
import Home from './pages/Home';
import NavbarK from './pages/Navbar';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import About from './pages/About';
import Footer from './pages/Footer';
import Shop from './pages/Shop';
import { CartProvider } from './pages/Cart';
import CartView from './pages/CartView';
import Contact from './pages/Contact';
import Success from './pages/Success';
import Checkout from './pages/Checkout';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


function App() {
  return (
    <CartProvider>
      <Router>
      <ScrollToTop />
        <NavbarK />
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/cart" element={<CartView />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/success" element={<Success />} />
            <Route path="/checkout" element={<Checkout />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </CartProvider>
  );
}

export default App;