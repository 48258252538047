import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
  return (
    <div>
      <div className="landing text-white position-relative slide-fade" style={{ height: '100vh', overflow: 'hidden' }}>
        <div
          className="position-absolute top-0 start-0 w-100 h-100 bg-img "
        ></div>

        <div className="container h-100 d-flex flex-column align-items-center text-center">
          <h1 className="fw-bold slide-fade display-4" style={{marginTop: '150px', zIndex: '999', background: 'none'}}>KINXE FOUNDERS COLLECTION</h1>
          <p className="lead slide-fade" style={{zIndex: '999', background: 'none'}}>Redefining Gaming, One Product at a Time.</p>
          <div className="d-flex gap-3 mt-3">
            <a href="/about" className="click">
              Learn More
            </a>
            <a href="/shop" className="click">
              View Shop
            </a>
          </div>
        </div>
      </div>

      <div className='row justify-content-center align-items-center d-flex pt-4' style={{overflowX: 'hidden'}}>
      <div className='col-md-5 h-100 d-flex flex-column align-items-center text-center bg-img-2' style={{marginRight: '20px'}}>
        <h1 className="fw-bold display-4" style={{paddingBottom: '350px', paddingTop: '100px', zIndex: '999', background: 'none'}}><span style={{position: 'relative', top: '-50px', background: 'none', color: 'white', fontSize: '40px'}}>KINXE FOUNDERS <br></br>MOUSE</span></h1>
          <div className="d-flex gap-3" style={{top:'-380px', position: 'relative', background: 'none'}}>
         
            <a href="/shop" className="click">
              Buy Now
            </a>
          </div>
        </div>
        <div className='col-md-5 h-100 d-flex flex-column align-items-center text-center bg-img-3'>
        <h1 className="fw-bold display-4" style={{paddingBottom: '350px', paddingTop: '100px', zIndex: '999', background: 'none'}}><span style={{position: 'relative', top: '-50px', background: 'none', color: 'white', fontSize: '40px'}}>KINXE FOUNDERS <br></br> KEYBOARD</span></h1>
          <div className="d-flex gap-3" style={{top:'-380px', position: 'relative', background: 'none'}}>
         
            <a href="/shop" className="click">
              Buy Now
            </a>
          </div>
        </div>
      </div>



      <div className='row justify-content-center align-items-center d-flex pt-4' style={{margin: '50px', overflowX: 'hidden'}}>

      <svg style={{marginTop: '40px'}} xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="white" class="bi bi-arrow-down" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
</svg>

      <h1 className="fw-bold display-4 white-text" style={{marginTop: '100px', marginBottom:'50px', zIndex: '999', background: 'none', fontSize: '40px'}}>WHERE CREATIVITY MEETS INNOVATION</h1>

        <p className='col-md-8 box-2 text-white pt-4' style={{padding: '50px'}}>

        Our gaming peripherals are meticulously crafted to elevate your gaming experience. We blend innovative design with cutting-edge technology to create devices that not only enhance performance but also reflect your unique style. 
        <br></br>
        <br></br>From precision-engineered mice to immersive headsets and responsive keyboards, each product is built with premium materials and ergonomic considerations for extended comfort. Our commitment to quality ensures durability and reliability, allowing you to focus on the game without distraction. Whether you're a casual gamer or a competitive pro, our peripherals are designed to empower you to reach new heights.

      <br></br>
      <br></br>
      <br></br>
        <a href="https://discord.gg/YsxpCsFpa2" target="_blank" className="d-flex click-2">
              Join our Community
            </a>
        </p>
      </div>
    </div>
  );
}

export default Home;
