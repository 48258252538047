import React from "react";


const Success = () => {

    return(

        <div>
            
            <h1 className="white-text pt-4">Ticket Submitted!</h1>
            <br></br>
            <a className="click-2 mt-5"  style={{margin:'20px'}} href="/">Go Home</a>
            <a className="click-2 mt-5" style={{margin:'20px'}} href="/shop">View Shop</a>

        </div>


    );
}

export default Success;