import React from 'react';
import { useCart } from './Cart';
import bundle from '../images/adverts.jpg';
import keyboard from '../images/keyboard.jpg';
import mouse from '../images/mouse.jpg';
import headset from '../images/headset.jpg';


const CartView = () => {
  const { cart, removeFromCart, updateQuantity, clearCart } = useCart();
  const total = cart.reduce((total, item) => total + item.price * (item.quantity || 1), 0).toFixed(2);


  return (
    <div className="cart-container slide-fade">
      <h2 className='white-text pt-4'>Your Cart  <h5 className='mt-3'>
          Total: $
          {cart
            .reduce(
              (total, item) => total + item.price * (item.quantity || 1),
              0
            )
            .toFixed(2)}
        </h5></h2>
      {cart.length === 0 ? (
        <p className='white-text'>Your cart is empty</p>
      ) : (
        <div>
          {cart.map((item, index) => (

                    <div className='row cart-item justify-content-center d-flex align-items-center' style={{margin: '10px'}}>
                    <div className='col-md-8 mt-5 box' id='bundle' style={{background: 'none'}}>
                        <div className='row d-flex justify-content-center' style={{margin: '10px'}}>
                            <div className='col-md-5 image-container d-flex justify-content-center align-items-center'>
                                <img src={item.image} className='zoom-image' style={{maxHeight: '290px'}}></img>
                            </div>
                            <div className='col-md  vertical-space'>
                                    <div className='row text-start'  style={{borderLeft: '1px solid white'}}>
                                        <h4 className='white-text'>{item.name} ⎯ ${item.price} </h4>
                                        <p className='white-text mt-3'>{item.description}</p> 
                                        <a className='click-2 text-center' style ={{padding: '10px', marginLeft: '10px', width: '100px'}} onClick={() => removeFromCart(index)}>Remove</a>
                                        <a className='click-2 text-center'
                onClick={() => updateQuantity(index, Math.max(1, (item.quantity || 1) - 1))} 
                style={{ padding: '10px', marginLeft: '5px', width: '100px' }}
              >
                -
              </a>
              <a className='click-2 text-center'
                onClick={() => updateQuantity(index, Math.max(1, (item.quantity || 1) + 1))} 
                style={{ padding: '10px', marginLeft: '5px', width: '100px' }}
              >
                +
              </a>
              <span className='white-text mt-3'> <h5>Quantity: {item.quantity || 1}</h5></span>
             
                                    </div>
                            </div>
                        </div>
                    </div>
                    </div>
          ))}
          <div className="cart-total white-text pt-3">
          <p>
          <a className='click-2' href='/checkout'>
          Checkout $
          {cart
            .reduce(
              (total, item) => total + item.price * (item.quantity || 1),
              0
            )
            .toFixed(2)}

<svg style={{background: 'none', marginLeft: '10px', marginBottom:'6px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-bag-check-fill" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0m-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
</svg>
        </a>
          
        </p>
        <p><i>*Shipping Not Included</i></p>
        <p><i>For any issues or inquiries, contact support@kinxegaming.com</i></p>
          </div>
        </div>
      )}


    </div>
  );
};

export default CartView;