import React from 'react';
import { NavLink } from 'react-router-dom';
// import NavbarLogo from '../images/allwhite.png';
import NavbarLogo from '../images/KinxeGaming_1.png';

const Navbar = () => {
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light mt-1" style={{ paddingBottom: '10px', background: 'none', borderRadius: '10px', margin: '10px' }}>
  <NavLink className="navbar-brand" to="/">
    <img id="navbar-logo" className="img-fluid" style={{ maxHeight: '40px', marginLeft: '40px' }} src={NavbarLogo} alt="ResDex Logo" />
  </NavLink>
  <button
    className="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ms-auto">
      <li className="nav-item">
        <NavLink exact to="/" className="nav-link nav-link-hover" activeClassName="active" style={{ marginLeft: '40px', marginRight: '40px' }}>
          <span className="white-text">Home</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink exact to="/about" className="nav-link nav-link-hover" activeClassName="active" style={{ marginLeft: '40px', marginRight: '40px' }}>
          <span className="white-text">About</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink exact to="/shop" className="nav-link nav-link-hover" activeClassName="active" style={{ marginLeft: '40px', marginRight: '40px' }}>
          <span className="white-text">Shop</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink exact to="/contact" className="nav-link nav-link-hover" activeClassName="active" style={{ marginLeft: '40px', marginRight: '40px' }}>
          <span className="white-text">Contact</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink exact to="/cart" className="nav-link nav-link-hover" activeClassName="active" style={{ marginLeft: '40px', marginRight: '40px' }}>
          <svg style={{ background: 'none' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-bag" viewBox="0 0 16 16">
            <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
          </svg>
        </NavLink>
      </li>
    </ul>
  </div>
</nav>

  );
};

export default Navbar;