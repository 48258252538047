import React from 'react'
import img2 from '../images/allwhite.png'

const About = () => {

return(

    <div className='container slide-fade'>
        <div className='row d-flex justify-content-center' style={{margin: '20px'}}>
            
          
             <div className='col-md-7  slide-fade mt-4 text-start d-flex box' style={{background: 'none', borderLeft: '3px solid #0ad100'}}>
            

                <p className='white-text' style={{paddingLeft: '20px'}}> 

                <h1 className='white-text'>About Us</h1>
             <br></br>
                    Kinxe Gaming is an online store founded in 2023 by a passionate gamer with a mission to provide high-quality gaming gear to take your play to the next level.
            
                    <br></br>
                                    <br></br>
                        At Kinxe Gaming, we’re redefining the gaming experience with cutting-edge peripherals designed for gamers, by gamers. Born out of a passion for immersive gameplay and relentless innovation, we create tools such as a Gaming Headser, Gaming Mouse, Gaming Mechanical Keybaord and Mousepad, whether you're leveling up in your favorite RPG, dominating the esports arena, or diving into uncharted virtual worlds. But we’re more than just products—we are a community. 
                        
                        <br>
                        </br>
                        <br></br>We are here to connect, inspire, and empower gamers to push boundaries and achieve greatness.
                                Innovation is our game, and quality is our promise. With ergonomic designs, customizable features, and durability you can count on, we aim to make every moment of your gaming journey extraordinary.   
                            <br></br>
                            <br></br>Welcome to Kinxe Gaming— <i >" where performance meets passion, and players become legends. "</i>


                 </p>
            </div>
{/* 
            <div className='col-md-3 offset-md-1 d-flex justify-content-center align-items-center'>
                <img src={img2} alt='Kinxe Logo' className='img-fluid' style={{maxHeight: '200px'}}/>
            </div> */}

    </div>  
        <div className='row d-flex justify-content-center slide-fade' style={{margin: '20px', marginTop: '100px'}}>
            

             <div className='col-md-7 mt-4 text-start d-flex box' style={{background: 'none', borderRight: '3px solid #0ad100'}}>
            

                <p className='white-text text-end'  style={{paddingRight: '20px'}}>

                <h1 className='white-text text-end'>Mission Statement</h1>
             <br></br>
             Our mission is to provide gamers worldwide with THE ULTIMATE POWER and cutting-edge peripherals that enhance performance, inspire creativity, and redefine immersive experiences. We are committed to delivering innovation, quality, and style that fuel the passion of every player, ensuring they reach their full potential in every game, every time.

                 </p>
            </div>

         

    </div>  
    </div>



);

}

export default About;