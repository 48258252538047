import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';

const Contact = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const sendTicket = (e) => {

    const isFormValid = fullName && email && subject && body;

    if (!isFormValid) {
      alert('Please fill in all the fields');
      return;
    }

    e.preventDefault();


    const templateParams = {
      full_name: fullName,
      email: email,
      subject: subject,
      message: body,
    };


    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSuccess('Contact Form Submission Received Successfully!');
          setError('');
          navigate('/success')
        },
        (err) => {
          console.error('FAILED...', err);
          setError('Failed to submit ticket. Please try again.');
          setSuccess('');
        }
      );
  };

  return (
    <div className='slide-fade'>
      <h1 className="white-text mt-5">Contact</h1>
      <div>
          <div className="row d-flex justify-content-center mt-5" style={{margin: '20px'}}>
            <div className='col-md-7 box' style={{background: 'none'}}>
            <Form className="contact-form" onSubmit={sendTicket} style={{padding: '15px'}}>
              <Form.Group className="mb-3" controlId="formBasicFullName" style={{background: 'none'}}>
                <Form.Label className='white-text d-flex text-start' style={{background: 'none'}}>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter full name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className='white-text d-flex text-start'>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicSubject">
                <Form.Label className='white-text d-flex text-start'>Subject</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicBody">
                <Form.Label className='white-text d-flex text-start'>Body</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter Message (Max 1500 Characters)"
                  value={body}
                  rows="5"
                  maxLength="1500"
                  onChange={(e) => setBody(e.target.value)}
                  required
                  style={{ height: '200px' }}
                />
              </Form.Group>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {success && <p style={{ color: 'green' }}>{success}</p>}
              <a onClick={sendTicket} className='d-flex click-2' style={{marginTop: '50px', backgroundColor: 'white', border: 'none', color: 'black' }} href=''>
                Submit Ticket
              </a>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;